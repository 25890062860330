import Cookies from 'js-cookie'

class CookieConsent {
  constructor() {
    this.consent = Cookies.get('consent') || 'false'
    this.consentForm = document.querySelector('#cookie-consent-form')
    this.dismissConsentButton = document.querySelector('#dismiss-consent-button')
    this.grantConsentButton = document.querySelector('#grant-consent-button')
    this.revokeConsentButton = document.querySelector('#revoke-consent-button')
  }


  /**
   * Adds consent form event listeners
   *
   * @memberof CookieConsent
   */
  addConsentListeners() {
    this.dismissConsentButton.addEventListener('click', () => {
      this.dismissCookieForm()
    })

    this.grantConsentButton.addEventListener('click', () => {
      this.grantConsent()
      this.dismissCookieForm()
    })

    this.revokeConsentButton.addEventListener('click', () => {
      this.revokeConsent()
      this.dismissCookieForm()
    })
  }


  /**
   * Deletes all cookies
   *
   * @memberof CookieConsent
   */
  deleteAllCookies() {
    const cookies = document.cookie.split(";")
    cookies.forEach((cookie) => {
      const key = cookie.split("=")[0].trim()
      Cookies.remove(key)
    })
  }


  /**
   * Hides the cookie consent form
   *
   * @memberof CookieConsent
   */
  dismissCookieForm() {
    this.consentForm.classList.remove('visible')
  }


  /**
   * Grants permission to store PII cookies
   *
   * @memberof CookieConsent
   */
  grantConsent() {
    Cookies.set('consent', 'true')
  }


  /**
   * Shows the cookie consent form
   *
   * @memberof CookieConsent
   */
  requestCookieConsent() {
    this.consentForm.classList.add('visible')
  }


  /**
   * Reject cookies and redirect to other page
   *
   * @memberof CookieConsent
   */
  revokeConsent() {
    this.deleteAllCookies()
    window.location.href = 'http://www.google.com/'
  }


  init() {
    if (Cookies.get('consent') === 'true') return

    this.requestCookieConsent()
    this.addConsentListeners()
  }
}

const cookies = new CookieConsent()
cookies.init()
