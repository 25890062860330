import throttle from 'lodash/throttle'

class Nav {
  constructor() {
    this.hamburgerButton = document.querySelector('nav button')
    this.menu = document.querySelector('#menu')
  }

  closeMenuOnResize() {
    window.addEventListener('resize', throttle(() => {
      this.menu.setAttribute('aria-expanded', 'false')
      this.hamburgerButton.classList.remove('is-active')
    }), 16)
  }

  openMenuOnButtonClick() {
    this.hamburgerButton.addEventListener('click', () => {
      const newState = this.menu.getAttribute('aria-expanded') === 'false'
      this.menu.setAttribute('aria-expanded', newState)

      if (newState === true) {
        this.hamburgerButton.classList.add('is-active')
      } else {
        this.hamburgerButton.classList.remove('is-active')
      }
    })
  }

  setMenuAsExpandedOnDesktop() {
    if (window.innerWidth >= 600) {
      this.menu.setAttribute('aria-expanded', true)
    }
  }

  init() {
    this.setMenuAsExpandedOnDesktop()
    this.closeMenuOnResize()
    this.openMenuOnButtonClick()
  }
}

const nav = new Nav()
nav.init()
